<template>
    <!-- site content -->
    <div class="main-page pt-3">
        <main class="main-container">
            <div class="container custom-container">
                <section>
                    <div class="row">
                        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex mb-3">
                            <div class="table-box p-3">
                                <div class="border-bottom pb-3">
                                    <div class="d-flex">
                                        <div class="flex-grow-1">
                                            <h2>{{ translations?.update_your_profile_info }}</h2>
                                        </div>
                                    </div>
                                </div>
                                <div class="my-3">
                                    <div class="row">
                                        <nav>
                                            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                                <button class="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home"
                                                        type="button" role="tab" aria-controls="nav-home" aria-selected="true">{{ translations?.profile_update }}</button>
                                                <button class="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile"
                                                        type="button" role="tab" aria-controls="nav-profile" aria-selected="false">{{ translations?.password_update }}</button>
                                                <button class="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact"
                                                        type="button" role="tab" aria-controls="nav-contact" aria-selected="false">{{ translations?.photo_update }}</button>
                                            </div>
                                        </nav>
                                        <div class="tab-content" id="nav-tabContent">
                                            <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                                <Form class="col-6" ref="resetForm" @submit="formAction(`${$config.User.UsersProfileEndPoint}/${$route.params.id}`,
                                    data.inputFormData, 'profile')" v-slot="{ errors }" :validation-schema="profileUpdate">
                                                    <div class="modal-body">
                                                        <div class="row">
                                                            <div class="col-12 mb-3">
                                                                <label class="form-control-label">{{ translations?.name }}</label>
                                                                <Field type="text" class="form-control" v-model="data.inputFormData.name"
                                                                       :class="{'border-danger': errors.name}" name="name"/>
                                                                <span class="text-danger" v-if="errors.name">{{ errors.name }}</span>
                                                            </div>
                                                            <div class="col-12 mb-3">
                                                                <label class="form-control-label">{{ translations?.email }}</label>
                                                                <Field type="text" class="form-control" v-model="data.inputFormData.email"
                                                                       :class="{'border-danger': errors.email}" name="email"/>
                                                                <span class="text-danger" v-if="errors.email">{{ errors.email }}</span>
                                                            </div>
                                                            <div class="col-12 mb-3">
                                                                <label class="form-control-label">{{ translations?.mobile }}</label>
                                                                <Field type="text" class="form-control" v-model="data.inputFormData.mobile"
                                                                       :class="{'border-danger': errors.mobile}" name="mobile"/>
                                                                <span class="text-danger" v-if="errors.mobile">{{ errors.mobile }}</span>
                                                            </div>
                                                            <div class="col-12 mb-3">
                                                                <label class="form-control-label"> language</label>
                                                                    <select
                                                                        class="form-select"
                                                                        aria-label="Language-select"
                                                                        v-model="data.inputFormData.locale"
                                                                    >
                                                                        <option
                                                                        v-for="lang in languages"
                                                                        :key="lang.id"
                                                                        :value="lang.language"
                                                                        >
                                                                        {{ lang.name }}
                                                                        </option>
                                                                    </select>
                                                                    <span class="text-danger" v-if="errors.type_id">{{ errors.type_id }}</span>
                                                                </div>

                                                            <div class="col-12 mb-3">
                                                                <button type="submit" class="btn btn-primary">{{ translations?.update }}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Form>
                                            </div>

                                            <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                                <Form class="col-6" ref="resetForm" @submit="formAction(`${$config.User.UsersPasswordEndPoint}/${$route.params.id}`,
                                    data.inputFormData, 'password')" v-slot="{ errors }" :validation-schema="passwordUpdate">
                                                    <div class="modal-body">
                                                        <div class="row">
                                                            <div class="col-12 mb-3">
                                                                <label class="form-control-label">{{ translations?.password }}</label>
                                                                <password name="password"
                                                                          class="form-control"
                                                                          :class="{'border-danger': errors.password}"
                                                                          v-model="data.inputFormData.password"
                                                                          placeholder="Password"/>
                                                                <span class="text-danger" v-if="errors.password">{{ errors.password }}</span>
                                                            </div>
                                                            <div class="col-12 mb-3">
                                                                <label class="form-control-label">{{ translations?.confirm_password }}</label>
                                                                <password name="c_password"
                                                                          class="form-control"
                                                                          v-model="data.inputFormData.c_password"
                                                                          placeholder="Confirm Password"/>
                                                                <span class="text-danger" v-if="errors.c_password">{{ errors.c_password }}</span>
                                                            </div>

                                                            <div class="col-12 mb-3">
                                                                <button type="submit" class="btn btn-primary">{{ translations?.update }} </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Form>
                                            </div>

                                            <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                                                <Form class="col-6" ref="resetForm" @submit="formAction(`${$config.User.UsersPhotoEndPoint}/${$route.params.id}`,
                                                    null, 'photo')" >
                                                    <div class="modal-body">
                                                        <div class="row">
                                                            <div class="col-12 mb-3">
                                                                <label class="form-control-label">{{ translations?.image }} </label>
                                                                <Field type="file" class="form-control" name="attachment" placeholder="image"
                                                                       @change="getFormFile($event, 'attachment')"/>
                                                            </div>
                                                            <div class="col-12 mb-3">
                                                                <img :src="data.imageUrl ? data.imageUrl : data.inputFormData.photo"
                                                                     alt="" class="img-fluid profile-image">
                                                            </div>

                                                            <div class="col-12 mb-3">
                                                                <button type="submit" class="btn btn-primary">{{ translations?.update }} </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </section>
            </div>
        </main>

        <BottomFooter></BottomFooter>
    </div>
    <!-- /site content end -->

</template>

<script>
    import {useStore} from "vuex";
    import {computed, onMounted,ref} from "vue";
    import HelperFunction from "@/common/helpers";
    import {Field, Form} from "vee-validate";
    import * as Yup from "yup";
    import {useRoute} from "vue-router";
    import Password from "@/common/components/input/Password";
    import BottomFooter from "@/views/layouts/auth/common/BottomFooter";
    import axios from "axios";

    export default {
        name: "ProfileUpdate",
        components: { Field, Form, Password, BottomFooter },
        computed: {
            translations() {
                return this.$store.state.translations;  
            },
        },
        setup() {
            const { storeFormData, data, editFormData, selectedCompany, getFormFile } = new HelperFunction()
            const store = useStore();
            const route = useRoute();
            const dashBoardData = computed(() => store.getters.getDashBoardData);
            const locale = localStorage.getItem("locale") || "en";
            data.inputFormData.locale = ref(locale ?? null);

            onMounted(() => {
                editFormData({url: `${vueConfig.User.UsersEndPoint}/${route.params.id}`});
                fetchLanguages();
            })

            const translations = computed(() => {
                return store.state.translations;  
            });

            const languages = ref([]);
            const fetchLanguages = async () => {
                try {
                    const response = await axios.get("/languages");
                    languages.value = response.data;
                } catch (error) {
                    console.error("Error fetching languages:", error);
                }
            };

            //VALIDATION
            const profileUpdate = Yup.object().shape({
                name: Yup.string().required(),
                email: Yup.string().required(),
                mobile: Yup.string().required()
            });
            const passwordUpdate = Yup.object().shape({
                password: Yup.string()
                    .min(8, "Password must be at least 8 characters")
                    .required("Password is required"),
                c_password: Yup.string()
                    .oneOf([Yup.ref('password'), null], 'Passwords must match')
                    .required('Confirm Password is required'),
            })
            const photoUpdate = Yup.object().shape({
                attachment: Yup.string().required(),
            })
            async function formAction(getUrl, getData, formName) {
                let inputData = {
                    _method: 'PUT',
                    company_id: selectedCompany.value.id ? selectedCompany.value.id : null
                }
                if (formName == 'profile') {
                    inputData.name = getData.name
                    inputData.email = getData.email
                    inputData.mobile = getData.mobile
                    inputData.locale = getData.locale
                } else if (formName == 'password') {
                    inputData.password = getData.password
                    inputData.c_password = getData.c_password
                }
                if (!data.imageUrl) {
                    inputData.attachment = data.inputFormData.photo
                }
                // console.error('ok', inputData); return
                await storeFormData({'url': getUrl}, inputData, `${vueConfig.User.UsersEndPoint}/${route.params.id}`)
                .then(() => {
                    localStorage.removeItem('user_info');
                    store.dispatch('userAction');
                })
            }

            return {
                dashBoardData, profileUpdate, data, formAction, passwordUpdate, getFormFile, photoUpdate, translations, languages
            }
        }
    }
</script>


<style scoped>

    .profile-image {
        width: 8vw;
        max-height: 20vh;
    }

</style>


<template>
    <div class="input-group">
        <Field :type="dataList.isPassword ? 'password' : 'text'"
               :value="value"
               v-bind="$attrs"
               @input="$emit('input', $event.target.value)"/>
        <span class="input-group-text"
              @click="toggle"
              style="cursor: pointer">
             <font-awesome-icon :icon="['fas', dataList.icon]"/>
        </span>
    </div>
</template>

<script>
import {Field} from "vee-validate";
import {reactive} from "vue";

export default {
    name: "Password",
    inheritAttrs: false,
    components: {Field},
    props: {
        value: {
            type: String,
            required: false
        }
    },
    setup() {
        let dataList = reactive({
            isPassword: true,
            icon: 'eye'
        });

        function toggle() {
            dataList.isPassword = !dataList.isPassword;
            dataList.icon = dataList.isPassword ? 'eye' : 'eye-slash';
        }

        return {
            dataList,
            toggle
        }
    }
}
</script>
